html {
  font-size: 62.5%
}

/* Use media queries to adjust font size for different viewport widths */
@media screen and (max-width: 500px) {
  /* Adjust font size for small screens */
  html {
    font-size: 40%;
  }
}

html, body {
  /*-webkit-user-select: none; !* Safari *!*/
  /*-moz-user-select: none; !* Firefox *!*/
  /*-ms-user-select: none; !* IE10+/Edge *!*/
  /*user-select: none; !* Standard syntax *!*/
  overscroll-behavior: none; /* Prevents scrolling from bouncing back at the edges */
}

html img, body img {
  -webkit-user-drag: none;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: rgba(249,246,224, 1);
  overscroll-behavior-y: contain;
  background-color: #5b5b5b;
}

body::-webkit-scrollbar {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
